import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from '../../components/ui/Wrapper';
import Text from '../../components/ui/Text';
import Button from '../../components/ui/button';
import Image from '../../components/Image';
import { HeroWrapper, HeroTextBox, ImageBoxWrap, ImageBoxOne, ImageBoxTwo } from './hero-area.style';
import SectionTitle from '../../components/ui/section-title';

const HeroArea = props => {
  const heroDataQuery = useStaticQuery(graphql`
    query heroQueryData {
      indexJson(id: { eq: "hero-content" }) {
        title
        subtitle
        shortDesc
        actionDesc
        path
        image1 {
          childImageSharp {
            fluid(maxWidth: 521, maxHeight: 502, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 706, maxHeight: 743, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `);
  const { title, subtitle, shortDesc, actionDesc, path, image1, image2 } = heroDataQuery.indexJson;
  const { btnStyle, sectionTitleStyle } = props;

  return (
    <HeroWrapper>
      <Container fluid>
        <Row alignitems="center">
          <Col md={6}>
            <HeroTextBox>
              <SectionTitle {...sectionTitleStyle} title={title} subtitle={subtitle} />
              {shortDesc && <Text>{shortDesc}</Text>}
              {path && (
                <Button {...btnStyle} to={path} target="_blank" uppercase>
                  {actionDesc}
                </Button>
              )}
            </HeroTextBox>
          </Col>
          <Col md={6}>
            <ImageBoxWrap>
              {image1 && (
                <ImageBoxOne>
                  <Image fluid={image1.childImageSharp.fluid} align="left" />
                </ImageBoxOne>
              )}
              {image2 && (
                <ImageBoxTwo>
                  <Image fluid={image2.childImageSharp.fluid} align="left" />
                </ImageBoxTwo>
              )}
            </ImageBoxWrap>
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
};

HeroArea.propTypes = {
  subtitleStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  btnStyle: PropTypes.object,
  sectionTitleStyle: PropTypes.object,
};

HeroArea.defaultProps = {
  subtitleStyle: {
    as: 'h6',
    color: 'primary',
    fontweight: 500,
    mb: '20px',
  },
  sectionTitleStyle: {
    mb: '30px',
    align: 'left',
  },
  titleStyle: {
    as: 'h1',
    mb: '15px',
    fontweight: 500,
  },
  btnStyle: {
    hover: '2',
    mt: '15px',
  },
};

export default HeroArea;
