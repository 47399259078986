import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Heading from '../../components/ui/Heading';
import Text from '../../components/ui/Text';
import { Col, Container, Row } from '../../components/ui/Wrapper';
import { CtaTextBox, SectionWrap } from './methodology.style';

const Methodology = ({ headingStyle, textStyle }) => {
  const methodologyData = useStaticQuery(graphql`
    query ProcessingCtaQuery {
      indexJson(id: { eq: "methodology-content" }) {
        title
        textP1
        textP2
        textP3
        textP4
      }
    }
  `);
  const { title, textP1, textP2, textP3, textP4 } = methodologyData.indexJson;
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <CtaTextBox>
              {title && <Heading {...headingStyle}>{title}</Heading>}
              <Text {...textStyle}>{textP1}</Text>
              <Text {...textStyle}>{textP2}</Text>
              <Text {...textStyle}>{textP3}</Text>
              <Text {...textStyle}>{textP4}</Text>
            </CtaTextBox>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

Methodology.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

Methodology.defaultProps = {
  headingStyle: {
    fontSize: '40px',
    lineHeight: 1.13,
    mb: '30px',
    responsive: {
      medium: {
        fontSize: '42px',
      },
      small: {
        fontSize: '32px',
      },
    },
  },
  textStyle: {
    fontSize: '18px',
    fontweight: 500,
    lineheight: 1.4,
    color: '#333333',
  },
};

export default Methodology;
