import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ImagesCarousel from '../../components/ImagesCarousel';

const CarouselHero = () => {
  const bannerDataQuery = useStaticQuery(graphql`
    {
      allContentfulHomeBanner(filter: { availableOn: { eq: "Mais Militar" } }, sort: { order: ASC, fields: order }) {
        nodes {
          actionLink
          id
          image {
            fluid(maxWidth: 1920, maxHeight: 544, quality: 100, toFormat: WEBP) {
              ...GatsbyContentfulFluid
            }
          }
          mobileImage {
            fluid(maxWidth: 700, maxHeight: 700, quality: 100, toFormat: WEBP) {
              ...GatsbyContentfulFluid
            }
          }
          order
          slug
          title
        }
      }
    }
  `);
  const slides = bannerDataQuery.allContentfulHomeBanner.nodes;

  return <ImagesCarousel slides={slides} />;
};

export default CarouselHero;
