import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from '../../components/ui/Wrapper';
import SectionTitle from '../../components/ui/section-title';
import Anchor from '../../components/ui/Anchor';
import Box from '../../components/box-large-image/layout-one';
import { SectionWrap, SectionBottom } from './career.style';

const Career = props => {
  const featuredDataQuery = useStaticQuery(graphql`
    query servicesDataQuery {
      site {
        siteMetadata {
          social {
            whatsapp
          }
        }
      }
      indexJson(id: { eq: "career-content" }) {
        title
        subtitle
      }
      allCareerJson(filter: { is_featured: { eq: false } }) {
        edges {
          node {
            slug
            id
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 370, quality: 100, webpQuality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  `);
  const servicesSecData = featuredDataQuery.indexJson;
  const servicesData = featuredDataQuery.allCareerJson.edges;
  const {
    social: { whatsapp },
  } = featuredDataQuery.site.siteMetadata;
  const { anchorStyle } = props;
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle title={servicesSecData.title} subtitle={servicesSecData.subtitle} />
          </Col>
        </Row>
        <Row gutters={{ lg: 70 }}>
          {servicesData.map(service => (
            <Col lg={6} md={6} className="box-item" key={service.node.id}>
              <Box
                title={service.node.title}
                desc={service.node.excerpt}
                imageSrc={service.node.image.childImageSharp}
                path={`${service.node.slug}`}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col lg={12}>
            <SectionBottom>
              <Anchor {...anchorStyle} path={whatsapp} target="_blank">
                Preciso de ajuda para decidir <IoIosArrowRoundForward className="ml-1" />
              </Anchor>
            </SectionBottom>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

Career.propTypes = {
  anchorStyle: PropTypes.object,
};

Career.defaultProps = {
  anchorStyle: {
    color: 'primary',
    layout: 'underline',
    fontSize: '18px',
    fontWeight: 500,
    lineheight: 1.4,
    hover: {
      layout: 2,
    },
  },
};

export default Career;
